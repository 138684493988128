
.serif-titulo {
    font-family: "Playfair Display", serif;
    font-optical-sizing: auto;
    font-weight: 450;
    font-style: normal;
  }
  
  .serif-texto {
      font-family: "Playfair Display", serif;
      font-optical-sizing: auto;
      font-weight: 400;
      font-style: normal;
    }